import React from 'react'

function Footer() {
  return (
    <footer className="attribution">
      Formation TypeScript
      <a href="https://www.mikecodeur.com" target="_blank" rel="noreferrer">
        Mike Codeur
      </a>
      .
    </footer>
  )
}

export {Footer}
